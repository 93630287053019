import { MDXProvider } from '@mdx-js/react'
import Breadcrumbs from 'components/Breadcrumbs'
import Layout from 'components/Layout'
import Link from 'components/Link'
import { Section } from 'components/Section'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import React from 'react'
import { shortcodes } from '../mdxGlobalComponents'
import CreateDashboardImage from '../images/create-dashboard.png'

// export const Head = ({ location, params, data: { pageData } , pageContext }) => (
//     <SEO2
//     title={`${pageData?.frontmatter?.title}  dashboard`}
//     description={pageData?.frontmatter?.description || pageData?.excerpt}
//     image={ `/images/dashboards/${pageData?.frontmatter?.slug?.split('/')[2]}.png`  }
// />
//   )

  export default function Dashboard(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { pageData } = data
    const {
        body,
        excerpt,
        parent,
        fields: { slug },
    } = pageData
    const { title, subtitle, featuredImage, description } = pageData?.frontmatter
    const featuredImg = featuredImage || parent?.cover?.image
    const components = {
      ...shortcodes(pageProps),
      Section: (props) => <Section {...props} {...pageProps} />,
    }
    return (
        <Layout >
    <SEO
    title={`${pageData?.frontmatter?.title}  dashboard`}
    description={pageData?.frontmatter?.description || pageData?.excerpt}
    image={ `/images/dashboards/${pageData?.frontmatter?.slug?.split('/')[2]}.png`  }
/>
            <Breadcrumbs
                crumbs={[{ title: 'Dashboards', url: '/dashboards' }, { title }]}
                darkModeToggle
                className="px-4 mt-4 sticky top-0 z-10 bg-tan dark:bg-primary"
            />
            <div
                style={{ gridAutoColumns: 'minmax(max-content, 1fr) minmax(auto, 880px) 1fr' }}
                className="mt-10 w-full relative lg:grid lg:grid-flow-col lg:gap-12 items-start"
            >
                <section>
                    <div className="lg:max-w-[880px] lg:pr-5 px-5 lg:px-0 mx-auto">
                        <h1 className="text-center mt-0 mb-12 hidden lg:block">{title}</h1>
                        <h2 className="text-center mt-0 mb-6">{subtitle}</h2>
                      {featuredImg &&  <GatsbyImage image={featuredImg && getImage(featuredImg)} alt="" />}
                        <article>
                            <MDXProvider components={components}>
                                <MDXRenderer>{body}</MDXRenderer>
                            </MDXProvider>
                        </article>
                        <article>
                            <div className="m-6 mb-12">
                                <p className="m-0 text-[15px]">
                                    To use this dashboard,{' '}
                                    <Link to="https://app.icloud-ready.com/dashboard"> go to the Dashboards tab</Link>,
                                    click the "New dashboard" button, and select "{title}" from the modal.
                                </p>
                                <img className="w-full mt-6" src={CreateDashboardImage} alt="" />
                            </div>
                        </article>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export const query = graphql`
query Dashboard($id: String!) {
    pageData: mdx(id: {eq: $id}) {
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      parent {
        ... on GoogleDocs {
          cover {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
